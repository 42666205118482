<template>
  <div>
    <v-container fluid class="py-8">
      <Notify ref="myNotify" :details="message"></Notify>
      <v-row>
        <v-col cols="12">
          <v-col cols="12">
            <v-card color="light">
              <v-app-bar color="light" class="border-left-primary">
                <v-toolbar-title>{{
                  $t("philosophys.philosophys")
                }}</v-toolbar-title>
                <button
                  v-if="
                    $store.getters['auth/str_per'].indexOf(
                      'cms-philosophies-create'
                    ) > -1
                  "
                  class="py-5 mb-0 ms-auto"
                  @click="add_philosophys()"
                >
                  <v-icon class="custome-btn btn-primary">fas fa-plus</v-icon>
                </button>
              </v-app-bar>
              <FormFilter
                :filters="filters"
                @get_data="get_philosophys"
                @reset="resetFilters"
              ></FormFilter>
              <Table
                ref="table"
                @set_option="set_option"
                @change_pre_page="ChangePrePage"
                @get_data_paginate="get_data_paginate"
                @action="action"
                :loading="loading"
                :btn_loading="btn_loading"
                :btns="btns"
                :headers="header"
                :paginate="paginate"
                :btn_table="btn_table"
                :items="philosophys"
              ></Table>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
      <Modal :data="modal_data"></Modal>
    </v-container>
  </div>
</template>
<script>
import Table from "../Components/PaginationTable.vue";
import FormFilter from "../Components/Filter.vue";
import Notify from "../Components/New/Notify.vue";
import Modal from "../Components/New/Modal.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("cms");
export default {
  name: "Philosophys-Page",
  components: {
    Table,
    Notify,
    FormFilter,
    Modal,
  },
  data() {
    return {
      options: {},
      filters: [],
      btn_table: {
        excel: false,
        print: false,
      },
      btn_loading: {
        excel: false,
        print: false,
      },
      paginate: {
        page: 1,
        itemsPerPage: 0,
        total: 0,
      },
      modal_data: {
        size: "500px",
        title: "",
      },
      loading: true,
      message: {
        msg: null,
        type: null,
      },
      btns: [
        {
          type: "icon",
          text: "edit",
          color: "bg-gradient-success",
          icon: "mdi-pencil",
          permission: "cms-philosophies-update",
        },
        {
          type: "icon",
          text: "destroy",
          color: "bg-gradient-danger",
          icon: "mdi-delete",
          permission: "cms-philosophies-delete",
        },
      ],
      header: [
        { text: this.$i18n.t("cycle.title"), value: "title", align: "center" },
        { text: this.$i18n.t("news.body"), value: "body", align: "center" },
        {
          text: this.$i18n.t("general.Action"),
          value: "btns",
          align: "center",
        },
      ],
      form_data: {
        title: "",
        body: "",
      },
      form_style: [
        {
          col: "12",
          type: "text",
          label: this.$i18n.t("cycle.title"),
          error: null,
          value_text: "title",
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "12",
          type: "textarea",
          rows: 6,
          label: this.$i18n.t("news.body"),
          error: null,
          value_text: "body",
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
      ],
    };
  },
  computed: {
    ...mapState(["philosophys"]),
  },
  methods: {
    ...mapActions(["getPhilosophys", "destroyPhilosophy"]),
    get_philosophys(reset = false, type = null) {
      this.loading = true;
      const formData = new FormData();
      if (!reset) {
        this.filters.filter(function (filter) {
          formData.append(filter.name, filter.value);
        });
      }
      if (type == "pre_page") {
        formData.append("paginate", this.paginate.itemsPerPage);
      }
      this.getPhilosophys({ page: this.paginate.page, data: formData }).then(
        (response) => {
          this.loading = false;
          this.paginate.total = response.data.philosophys.total;
          this.paginate.itemsPerPage = parseInt(
            response.data.philosophys.per_page
          );
          if (reset) {
            this.filters = response.data.filters;
            this.options = {};
            this.$refs.table.resetOption();
          }
        },
        (error) => {
          console.log(error);
          this.loading = false;
          if (error.response.status != 401) {
            this.$store.dispatch("form/setNotify", {
              msg: this.$i18n.t("general.there is problem"),
              type: "Danger",
            });
          }
        }
      );
    },
    set_option(val) {
      this.options = val;
    },
    get_data_paginate(page) {
      this.paginate.page = page;
      this.get_philosophys();
    },
    ChangePrePage(page) {
      this.paginate.itemsPerPage = parseInt(page);
      this.paginate.page = 1;
      this.get_philosophys(false, "pre_page");
    },
    resetFilters() {
      this.paginate.page = 1;
      this.get_philosophys(true);
    },
    action(id, name, item) {
      if (name == "edit") {
        this.edit_philosophys(item);
      } else if (name == "destroy") {
        this.destroy_philosophys(id);
      }
    },
    add_philosophys() {
      this.$store.commit("form/SET_DIALOG", true);
      this.modal_data.title = this.$i18n.t("philosophys.New Philosophys");
      this.$store.dispatch("form/setFormData", this.form_data);
      this.$store.dispatch("form/setData", this.form_style);
      this.$store.commit("form/INFO_CALL", {
        name: "cms/addPhilosophy",
      });
    },
    edit_philosophys(item) {
      this.$store.commit("form/SET_DIALOG", true);
      this.modal_data.title =
        this.$i18n.t("philosophys.Edit Philosophys") + " # " + item.title;
      this.$store.dispatch("form/setData", this.form_style);
      this.form_data.title = item.title;
      this.form_data.body = item.body;
      this.$store.dispatch("form/setFormData", this.form_data);
      this.$store.commit("form/INFO_CALL", {
        name: "cms/editPhilosophy",
        id: item.id,
      });
    },
    destroy_philosophys(id) {
      this.$swal({
        title: this.$i18n.t("general.Are you sure?!"),
        text: this.$i18n.t("You won't be able to revert this!"),
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: this.$i18n.t("general.Yes, delete it!"),
        cancelButtonText: this.$i18n.t("general.No, cancel!"),
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.destroyPhilosophy(id).then(
            (response) => {
              // console.log(response)
              this.$swal.fire(
                this.$i18n.t("general.Deleted!"),
                response.data.message,
                "success"
              );
            },
            (error) => {
              console.log(error);
              this.$swal.fire(
                this.$i18n.t("general.Error"),
                this.$i18n.t("general.There error please try again"),
                "error"
              );
            }
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.fire(
            this.$i18n.t("general.Cancelled"),
            this.$i18n.t("general.Cancelled Delete"),
            "error"
          );
        }
      });
    },
  },
  mounted() {
    this.get_philosophys(true);
    document.title = this.$i18n.t("philosophys.philosophys");
  },
};
</script>
